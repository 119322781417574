import React from "react"
import { Twitter } from "styled-icons/boxicons-logos/Twitter"
import { Linkedin } from "styled-icons/boxicons-logos/Linkedin"
import { MailSend } from "styled-icons/boxicons-regular/MailSend"
import { Facebook } from "styled-icons/boxicons-logos/Facebook"

// import emotion
// import { css } from "emotion"

// import react-custom-share components
import { ShareButtonRoundSquare, ShareBlockStandard } from "react-custom-share"

const Share = ({ slug, description, title }) => {
  // create object with props for shareBlock
  const shareBlockProps = {
    url: `https://www.charlesassuncao.tech${slug}`,
    button: ShareButtonRoundSquare,
    buttons: [
      { network: "Twitter", icon: Twitter },
      { network: "Facebook", icon: Facebook },
      { network: "Linkedin", icon: Linkedin },
      { network: "Email", icon: MailSend },
    ],
    text: title,
    longtext: `"${description}..."`,
  }

  return <ShareBlockStandard {...shareBlockProps} />
}

export default Share
